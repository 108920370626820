<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],
      loading: false,
      userinfo: [],
      micoapplist: [],
    };
  },
  mounted() {
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    this.title = this.$t("menuitems.icp.micoapp.list");
    this.items = [
      {
        text: this.$t("menuitems.micoapp.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.icp.micoapp.list"),
        active: true,
      },
    ];
    this.getmicoapplist();
  },
  methods: {
    getmicoapplist() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "getmicoapplist",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.micoapplist = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    createmcioapp() {
      var that = this;
      if (that.micoapplist.length > 0) {

        that.$router.push({ path: "/micoapp/create" });

      } else {
        that.$router.push({ path: "/micoapp/create" });
      }
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <el-button type="primary" round @click="createmcioapp">创建小程序</el-button>
            <el-table :data="micoapplist" stripe style="width: 100%">
              <el-table-column prop="niconame" label="名称"></el-table-column>
              <el-table-column label="头像">
                <template slot-scope="scope">
                  <el-avatar size="large" :src="scope.row.head_img"></el-avatar>
                </template>
              </el-table-column>
              <el-table-column prop="appid" label="AppID"></el-table-column>
              <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                  <router-link :to="{
      path: '/micoapp/' + scope.row.appid
    }"><el-button type="primary" size="mini">管理</el-button></router-link>

                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>